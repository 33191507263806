import React, { useEffect, useState } from 'react';
import { Dialog, Grid, Button, Tooltip } from '@material-ui/core';
import { isMobile, isMobileOnly, RenderTextField, RenderMultiSelect } from 'axeleratum-sgc-frontend-library';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import DropzoneFiles from './dropzone-files';
import { DocumentsHttp } from '../../core/http/documents.http';
import TemplateUsage from '../templates/template-usage';
import { deleteFromStorageProvider } from '../../core/http/functionRequests';
import { tokenExpired } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../core/actions';
import { connect } from 'react-redux';

let DialogFormDocumentFN = (props) => {

	const {
		documentSelected,
		openDialog,
		submitActions,
		tagsSelect,
		handleSubmit,
		typeForm,
		onCancel,
		initialize,
		collaborators,
		company,
		currentUser,
		docName,
	} = props;

	const [template, setTemplate] = useState(null)
	const [enableEdit, setEnableEdit] = useState(false)
	const [documentInfo, setDocumentInfo] = useState(null)
	const [documentName, setDocumentName] = useState(null)
	const [uploadingFile, setUploadingFile] = useState(false)
	const [showEditTemplate, setShowEditTemplate] = useState(false)
	const [documentExtension, setDocumentExtension] = useState(null)
	const [openTemplateUsage, setOpenTemplateUsage] = useState(false)

	const documentsHttp = new DocumentsHttp();
	const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
	const dispatch = useDispatch();

	const getTemplate = (templateId) => {


		if (isLoggedIn && tokenExpired()) {
			dispatch(authActions.userLoggedOut())
			return
		}
		documentsHttp.getDocumentInfo(templateId, resp => {
			const template = {
				id: resp ? resp._id : null,
				name: resp ? resp.Name : '',
				description: resp ? resp.Description : '',
				tags: resp.Tags,
				documentTypeId: resp ? resp.DocumentTypeId : '',
				documentType: resp ? resp.DocumentType.Name : '',
				ownerId: resp ? resp.Owner : '',
				nameDisplay: resp ? resp.NameDisplay : '',
				extension: resp ? resp.Extension : ''
			};

			setTemplate(template)
		}, () => { });
	}

	const validateDocumentUpdate = (documentId) => {



		if (isLoggedIn && tokenExpired()) {
			dispatch(authActions.userLoggedOut())
			return
		}
		documentsHttp.validateDocumentUpdate(documentId, resp => {
			setEnableEdit(resp)
		}, () => { });
	}

	const onCancelActivity = () => {
		if (typeForm !== "update" && documentName) {
			deleteFromStorageProvider(`${documentName}${documentExtension}`)
			onCancel()
		} else {
			onCancel()
		}
	}

	const setOriginalName = (name) => {
		initialize({ name })
	}

	useEffect(() => {

		if (isLoggedIn && tokenExpired()) {
			dispatch(authActions.userLoggedOut())
			return
		}

		if (typeForm === 'update' && documentSelected !== null) {
			validateDocumentUpdate(documentSelected.id);

			if (documentSelected.hasTemplate) {
				getTemplate(documentSelected.templateId);
			}



			documentsHttp.getDocumentInfo(
				documentSelected.id,
				(document) => {
					const documentInfo = {
						id: document._id,
						name: document ? document.Name : '',
						description: document ? document.Description : '',
						tags: document.Tags,
						path: document ? document.Path : '',
						folderId: document.FolderId,
						extension: document.Extension,
						templateId: document.TemplateId,
						companyId: document.CompanyId
					};

					if (document.StatusBpm === "Preparar Revisión") {
						setShowEditTemplate(true)
					}

					initialize({
						name: document ? document.Name : '',
						description: document ? document.Description : '',
						tags: document
							? document.Tags.map((tag) => {
								if (tag !== '') {
									return { label: tag, value: tag };
								} else {
									return null;
								}
							})
							: [],
						users: collaborators,
					});

					setDocumentInfo(documentInfo)
				}, (err) => { console.error(err); }
			);

		}
	}, [])

	return (
		<Dialog className={isMobile ? "" : "w-45-dialog"} open={openDialog}>
			<form
				className={isMobileOnly ? "p-3" : "p-4"}
				onSubmit={handleSubmit(submitActions)}>
				<Grid
					style={{
						marginTop: "-0.8rem",
					}}>
					<h3>{typeForm === "update" ? "Editar" : "Crear"} Documento</h3>
				</Grid>

				<Grid>
					{typeForm !== "update" && (
						<Grid item xs={12}>
							<h3 style={{ fontSize: "1rem", marginTop: "1rem" }}>
								Adjuntar Archivo*
							</h3>
							<Field
								name={"file"}
								component={DropzoneFiles}
								props={{
									onInitUploadFile: (done) => setUploadingFile(done),
									setDocumentExtension,
									setDocumentName,
									setOriginalName,
									originalName: docName ?? '',
								}}
							/>
						</Grid>
					)}

					<Grid
						style={{
							marginTop: "1rem",
						}}
						item
						xs={12}>
						<Field
							label={`Nombre del documento* (${docName?.length ?? 0
								} de 300 caracteres)`}
							name="name"
							component={RenderTextField}
							normalize={(value, previousValue) => {
								if (value) {
									if (value.length > 300) {
										const newValue = value.slice(0, 300);
										return newValue;
									} else if (value !== " ") {
										return value;
									}
								} else {
									return value;
								}
							}}
						/>

						<Field
							label="Descripción (opcional)"
							name="description"
							component={RenderTextField}
						/>

						<Field
							label="Seleccionar etiquetas (opcional)"
							name="tags"
							options={tagsSelect}
							component={RenderMultiSelect}
						/>
					</Grid>

					{typeForm === "update" &&
						documentSelected &&
						documentSelected.hasTemplate && (
							<Grid item xs={12}>
								<h3>Contenido de documento</h3>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setOpenTemplateUsage(true)}
									disabled={
										!template ||
										!documentInfo ||
										!enableEdit ||
										!showEditTemplate
									}>
									Editar contenido
								</Button>
							</Grid>
						)}

					{openTemplateUsage && template && (
						<TemplateUsage
							business={company}
							currentUser={currentUser}
							templateSelected={template}
							documentSelected={documentInfo}
							open={openTemplateUsage}
							onClose={() => setOpenTemplateUsage(false)}
						/>
					)}

					<Grid
						style={{
							marginBottom: "0.5rem",
						}}
						className="mt-3"
						container
						justify="flex-end">
						{isMobile ? (
							<React.Fragment>
								<Grid item>
									<Button
										variant="outlined"
										color="secondary"
										onClick={onCancelActivity}
										disabled={typeForm === "create" && uploadingFile}>
										Cancelar
									</Button>
								</Grid>

								<Grid item>
									<Tooltip
										title={
											typeForm === "update" && !enableEdit
												? "El documento no se puede editar porque está en proceso de revisión ó firma"
												: ""
										}>
										<span>
											<Button
												className="ml-2"
												type="submit"
												variant="contained"
												color="primary"
												disabled={typeForm === "update" && !enableEdit}>
												Crear
											</Button>
										</span>
									</Tooltip>
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Button
									variant="outlined"
									color="secondary"
									onClick={onCancelActivity}
									disabled={typeForm === "create" && uploadingFile}>
									Cancelar
								</Button>

								<Tooltip
									title={
										typeForm === "update" && !enableEdit
											? "El documento no se puede editar porque está en proceso de revisión ó firma"
											: ""
									}>
									<span>
										<Button
											className="ml-2"
											type="submit"
											variant="contained"
											color="primary"
											disabled={
												(typeForm === "update" && !enableEdit) || uploadingFile
											}>
											Guardar
										</Button>
									</span>
								</Tooltip>
							</React.Fragment>
						)}
					</Grid>
				</Grid>
			</form>
		</Dialog>
	);
}

const validate = (values) => {
	const errors = {
		name: ''
	}

	if (!values.name) {
		errors.name = 'El nombre del documento es requerido';
	}

	if (/["*:<>?/\\|.]/.test(values.name)) {
		errors.name = `El nombre no debe contener estos caracteres especiales:  " * : < > ? / \\ | .`;
	}

	if (values.name) {
		if (values.name.length > 300) {
			errors.name = 'El nombre del documento no puede ser mayor a 300 caracteres';
		}
	}

	return errors;
}

DialogFormDocumentFN = reduxForm({
	form: 'DialogFormDocument',
	validate,
})(DialogFormDocumentFN);


const selector = formValueSelector('DialogFormDocument');

const mapStateToProps = (state) => {
	const docName = selector(state, 'name')
	return {
		docName,
	}
}

export default connect(mapStateToProps)(DialogFormDocumentFN);
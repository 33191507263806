import React from 'react'
import { AppBar, Grid, Button, IconButton, makeStyles, Toolbar, Typography, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    marginLeft: '1rem'
  },
  navigate: {
    flex: '1',
    marginLeft: '11rem',
    textAlign: 'center'
  },
  navigatePage: {
    textAlign: 'right'
  },
  buttonNavigatePage: {
    borderRadius: '6px'
  },
}));

const NavBarPDF = (props) => {
  const {
    onClose,
    documentName,
    handlePrevPage,
    currentPage,
    totalPages,
    handleNextPage,
    onChangeHandler,
    inputValue,
    pageByInputRequest,
    zoom,
    setZoom,
  } = props;
  const classes = useStyles();


  const handleClose = () => {
    onClose()
  }

  const handleZoom = (type) => {
    if (type === "out") {
      if (zoom >= 1.25) {
        setZoom(prev => prev - 0.25)
      }
    }

    if (type === "in") {
      if (zoom <= 2.5) {
        setZoom(prev => prev + 0.25)
      }
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <Grid container spacing={3}>
            <Grid item container xs={4} alignItems='center'>
              <Grid item>
                <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Tooltip title={documentName}>
                  <Typography variant="subtitle1" className={classes.title}>
                    {
                      documentName.length > 40
                        ? documentName.slice(0, 37) + '...'
                        : documentName
                    }
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>


            <Grid
              item
              container
              alignItems='center'
              justifyContent='flex-end'
              xs={5}
              style={{ paddingRight: "50px" }}
              spacing={1}
            >
              <Grid item style={{ marginRight: "30px" }}>
                <IconButton
                  disabled={zoom <= 1}
                  onClick={() => handleZoom("out")}
                  style={{ width: "10px", height: "10px" }}
                >
                  <ZoomOutIcon style={{ color: 'white' }} />
                </IconButton>
                <IconButton
                  disabled={zoom >= 2.5}
                  onClick={() => handleZoom("in")}
                  style={{ width: "10px", height: "10px" }}
                >
                  <ZoomInIcon style={{ color: 'white' }} />
                </IconButton>
              </Grid>

              <Grid item>
                <Button onClick={handlePrevPage} variant="contained" color="secondary">
                  Previa
                </Button>
              </Grid>
              <Grid item>
                <span>
                  Página: <span>{currentPage}</span> / <span>{totalPages}</span>
                </span>
              </Grid>

              <Grid item>
                <Button onClick={handleNextPage} variant="contained" color="secondary">
                  Siguiente
                </Button>
              </Grid>
            </Grid>


            <Grid item container alignItems='center' justifyContent='flex-end' xs={3}>
              <Grid item>
                <input
                  type="number"
                  className={classes.buttonNavigatePage}
                  onChange={onChangeHandler}
                  size={totalPages}
                  id="inputValue"
                  value={inputValue}
                  name="inputValue"
                  min="1"
                  max={totalPages}
                />
                &nbsp; &nbsp;
                <Button onClick={pageByInputRequest} variant="contained" color="secondary">
                  Ir a Página
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBarPDF